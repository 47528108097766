import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import EditIcon from '@mui/icons-material/Edit';
import { Grid, Typography } from '@mui/material';

import {
    PRIMARY_COLOR,
    SECONDARY_COLOR,
    MINDYOURSELF_MAIN_COLOR,
    CANCEL_BUTTON_COLOR
} from '../../constant';
import { queryAPI } from '../../Api';

import {
  Notification
} from '../../Components/Notification';

export default function ProfileContainer() {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [maxWidth, setMaxWidth] = React.useState('md');
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [isUpdatePasswordClicked, setIsUpdatePasswordClicked] = React.useState(false);
  const [oldPassword, setOldPassword] = React.useState("")
  const [newPassword, setNewPassword] = React.useState("")
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [organisation, setOrganisation] = React.useState("");
  const [industry, setIndustry] = React.useState("");
  const password = "**************";
  const [isPasswordChangeProblem, setIsPasswordChangeProblem] = React.useState(false);
  const [isPasswordChanged, setIsPasswordChanged] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setConfirmOpen(false);
    setIsUpdatePasswordClicked(false)
    setOldPassword("")
    setNewPassword("")
    setIsPasswordChangeProblem(false)
    setIsPasswordChanged(false)
  };

  const handleStay = () => {
    setConfirmOpen(false);
  };

  const handleSaveChanges = () => {
    if (isUpdatePasswordClicked) {
      queryAPI('/user', {
        "user_id": localStorage.getItem('id'),
        "password": oldPassword,
        "new_password": newPassword
      }, 'PUT')
        .then((response) => {
          if (response.status != 200) {
            console.log("Password is not updated. Please try again.")
            setIsPasswordChangeProblem(true)
            // change back to false after 2 seconds
            setTimeout(() => {
              setIsPasswordChangeProblem(false)
            }, 2000)
          }
          else {
            setIsPasswordChanged(true)
            // change back to false after 2 seconds
            setTimeout(() => {
              setIsPasswordChanged(false)
            }, 2000)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
    setTimeout(() => {
      setIsUpdatePasswordClicked(false)
      setOldPassword("")
      setNewPassword("")
    }, 2000)    
  }


  const handleCloseConfirm = () => {
    setConfirmOpen(true);
  };

  // get data from backend
  React.useEffect(() => {
    queryAPI('/user?user_id='+localStorage.getItem('id'),'', 'GET')
      .then((response) => {
        console.log(response)
        setName(response.data.first_name + " " + response.data.last_name)
        setEmail(response.data.email)
        setPhone(response.data.phone_number)
        setOrganisation(response.data.organisation)
        setIndustry(response.data.industry)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])

  return (
    <Grid container>
      {isUpdatePasswordClicked && isPasswordChangeProblem && 
        <Notification
          severity="error"
          isOpen={true}
          message={"Password is not updated. Please try again."}
        />
      }
      {isUpdatePasswordClicked && isPasswordChanged &&
        <Notification
          severity="success"
          isOpen={true}
          message={"Password is updated successfully."}
        />
      }
    <Grid>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <EditIcon 
          data-testid="editProfile"
          style={{ color: MINDYOURSELF_MAIN_COLOR, marginRight: '8px'  }}
          onClick={handleClickOpen}
          onMouseEnter={(event) => event.target.style.cursor = "pointer"}
          />
        <Typography variant="body2" sx={{ color: MINDYOURSELF_MAIN_COLOR, fontWeight: 'bold' }}>
          Profile
        </Typography>
      </div>

      <Dialog
        fullScreen={fullScreen}
        open={open}
        maxWidth={maxWidth}
        onClose={handleCloseConfirm}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Profile details:"}
        </DialogTitle>

        <DialogContent>  
            <DialogContentText >
            <Table sx={{ minWidth: 500, maxWidth: 500 }} aria-label="simple table" >
                <TableBody>
                    <TableRow
                        key={name}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell component="th" scope="row" style={{ fontWeight: 'bold' }}>
                            Name
                        </TableCell>
                        <TableCell align="right">
                          {name}
                        </TableCell>
                    </TableRow>
                    <TableRow
                        key={email}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell component="th" scope="row" style={{ fontWeight: 'bold' }}>
                            Email
                        </TableCell>
                        <TableCell align="right">{email}</TableCell>
                    </TableRow>
                    <TableRow
                        key={phone}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell component="th" scope="row" style={{ fontWeight: 'bold' }}>
                            Phone
                        </TableCell>
                        <TableCell align="right">{phone}</TableCell>
                    </TableRow>
                    <TableRow
                        key={organisation}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell component="th" scope="row" style={{ fontWeight: 'bold' }}>
                            Organisation
                        </TableCell>
                        <TableCell align="right">{organisation}</TableCell>
                    </TableRow>
                    <TableRow
                        key={industry}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell component="th" scope="row" style={{ fontWeight: 'bold' }}>
                            Industry
                        </TableCell>
                        <TableCell align="right">{industry}</TableCell>
                    </TableRow>
                    {
                      !isUpdatePasswordClicked &&
                    <TableRow
                        key={password}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell component="th" scope="row" style={{ fontWeight: 'bold' }}>
                            Password
                        </TableCell>
                        <TableCell align="right">
                          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                          {password}
                          <EditIcon
                                style={{ color: MINDYOURSELF_MAIN_COLOR, marginLeft: '8px' }}
                                onClick={() => {setIsUpdatePasswordClicked(true)}}
                                onMouseEnter={(event) => event.target.style.cursor = "pointer"}
                            />
                          </div>
                        </TableCell>
                      </TableRow>
                    }                      
                </TableBody>
            </Table>
              
            {
              isUpdatePasswordClicked &&
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <TextField
                    variant='standard'
                    margin="normal"
                    type='password'
                    required
                    fullWidth
                    id="oldpassword"
                    name="oldpassword"
                    value={oldPassword}
                    onChange={(e) => setOldPassword(e.target.value)}
                    label="Old Password"
                    sx={{ width: '90%', marginLeft: 'auto', marginRight: 'auto' }}
                />
                <TextField
                    variant='standard'
                    type='password'
                    margin="normal"
                    required
                    fullWidth
                    id="newpassword"
                    name="newpassword"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    label="New Password"
                    sx={{ width: '90%', marginLeft: 'auto', marginRight: 'auto' }}
                />
              </div>
            }

            </DialogContentText>
         

        </DialogContent>
        <DialogActions sx={{ justifyContent: 'space-between', ml:2, mr:3, mb:1 }}>
          <Button onClick={handleClose} 
            sx={{ backgroundColor: CANCEL_BUTTON_COLOR, 
                    color: SECONDARY_COLOR, 
                    ":hover": { backgroundColor: CANCEL_BUTTON_COLOR, color: SECONDARY_COLOR }
                }}
            >
            Cancel
          </Button>
          <Button onClick={handleSaveChanges} sx={
                { backgroundColor: PRIMARY_COLOR, 
                    color: SECONDARY_COLOR, 
                    ":hover": { backgroundColor: SECONDARY_COLOR, color: PRIMARY_COLOR }
                }
          }>
            Save changes
          </Button>
        </DialogActions>
      </Dialog>


      <Dialog
        open={confirmOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={maxWidth}
      >
        <DialogTitle id="alert-dialog-title">{"Discard Changes?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to exit without saving?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleStay}
            sx={{ backgroundColor: CANCEL_BUTTON_COLOR,
                    color: SECONDARY_COLOR }}
            autoFocus>
            Cancel
          </Button>
          <Button onClick={handleClose}
            sx={{ backgroundColor: PRIMARY_COLOR,
                    color: SECONDARY_COLOR }}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
    </Grid>
  );
}

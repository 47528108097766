import LoginForm from "./Pages/Login/index";
import ResetPassForm from "./Pages/ResetPassword/index";
import ForgotPassForm from "./Pages/ForgotPassword/index";
import RegisterForm from "./Pages/SignUp/index";
import Home from "./Pages/Home/index";

const dashboardRoutes = [
    {
      path: "/register",
      name: "register",
      component: RegisterForm
    },  
    {
      path: "/login",
      name: "login",
      component: LoginForm
    },
    {
      path: "/reset-password",
      name: "Reset Password",
      component: ResetPassForm
    },
    {
      path: "/forgot-password",
      name: "forgot Password",
      component: ForgotPassForm
    },
    {
      path: "/home",
      name: "home",
      component: Home
    },
  ];
  
  export default dashboardRoutes;
  
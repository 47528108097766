import * as React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import { Typography } from '@mui/material';
import { Redirect } from 'react-router-dom';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import {
  queryAPI
} from '../../Api';
import {
  Notification
} from '../../Components/Notification';
import {
  setLoginStatus,
  setNotificationMsg,
  setUser
} from '../../redux/User/user.action';

import {
  PRIMARY_COLOR,
  SECONDARY_COLOR,
  MINDYOURSELF_MAIN_COLOR,
  CANCEL_BUTTON_COLOR
} from '../../constant';

function SignInContainer({ setLoginStatus, isSuccessfullLogin, setNotificationMsg, notificationMsg, setUser }) {
  const [isLoggedIn, setIsLoggedIn] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false)
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const [isMobile, setIsMobile] = React.useState(false);
  React.useEffect(() => {
    // Check if user is on a mobile device
    const isMobileDevice = window.matchMedia("screen and (max-width: 768px)").matches;
    setIsMobile(isMobileDevice);
    console.log("isMobileDevice: ", isMobileDevice);
  }, []);

  React.useEffect(()=>{
    localStorage.clear();
  },[])

  const handleSubmit = (event) => {
    setIsLoading(true)
    event.preventDefault();
    setLoginStatus(true);
    const data = new FormData(event.currentTarget);
    const user_data = {
      email: data.get('email'),
      password: data.get('password'),
    }
    queryAPI('/token', user_data, 'POST')
      .then(response => {
        if (response.response && 
          [400, 401, 409].includes(response.response.status)) {
            setNotificationMsg("The username or password is incorrect")
            setIsLoggedIn(false)
            setLoginStatus(false)
        } else if (response.response && 
          response.response.status === 403) {
            setNotificationMsg("The account has not been activated yet")
            setIsLoggedIn(false)
            setLoginStatus(false)
        } else if (response.response && 
          response.response.status !== 200) {
            if (response.message) {setNotificationMsg(response.message)}
            setIsLoggedIn(false)
            setLoginStatus(false)
        } else if (response && 
          response.status === 200) {
            localStorage.setItem('auth_token', response.data.token);
            setUser(response.data)
            setIsLoggedIn(true)
            setLoginStatus(true)
        } else {
          setNotificationMsg("login failed")
          setIsLoggedIn(false)
          setLoginStatus(false)
        }
        setIsLoading(false)
      })
  };

  return (
    <Grid container style={{ minHeight: '100vh' }}>
    
    <Grid container style={{ minHeight: '100vh' }} backgroundColor={MINDYOURSELF_MAIN_COLOR}>
      {
        isLoggedIn && <Redirect to='/home' />
      }
      {!isSuccessfullLogin &&
        <Notification
          severity="error"
          isOpen={true}
          message={notificationMsg} />
      }
      
      <Grid container item xs={isMobile ? 12 : 6} align = "center" justify="center">
          <Grid item xs={12} sx={{ height: '10%', display: 'flex', alignItems: 'center' }}>
            <img
              src="/images/mindyourself_logo.png"
              width="170px"
              alt="affinti_logo"
              style={{ float: 'left', marginLeft: '10%' }}
            />
          </Grid>

          <Grid item xs={12} sx={{ height: '90%' }} >
          <Grid item sx={{ width: "80%", float:"center" }} >
            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 10 }}>
              <Typography
                variant="h2"
                color="white"
                sx={{ float: "left" }}
              >
                Login
              </Typography>
              <KeyboardArrowRightIcon sx={{ 
                float: "left", mt: 1, ml: 1, color: PRIMARY_COLOR,
                ":hover": { color: SECONDARY_COLOR },
                fontSize: '50px'
              }} />
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                data-testid = 'loginEmailInput'
                placeholder="Email"
                name="email"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                placeholder="Password"
                type={showPassword ? 'text' : 'password'}
                id="password"
                data-testid = 'loginPasswordInput'
                InputProps={{
                  endAdornment: 
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>,
                }}
              />
              <LoadingButton
                data-testid = 'loginSubmitButton'
                type="submit"
                fullWidth
                variant="contained"
                loading={isLoading}
                sx={{ mt: 3, mb: 2, backgroundColor: PRIMARY_COLOR, height: "45px", color: SECONDARY_COLOR,
                  ":hover": { backgroundColor: SECONDARY_COLOR, color: PRIMARY_COLOR } }}
              >
                Login
              </LoadingButton>
              <Grid container>
                <Grid item xs>
                  <Link href="/forgot-password" variant="body2" sx={{ 
                    float: "left", 
                    color: CANCEL_BUTTON_COLOR, 
                    ":hover": { color: SECONDARY_COLOR }
                  }}>
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="/register" variant="body2" sx={{ 
                    color: CANCEL_BUTTON_COLOR, 
                    ":hover": { color: SECONDARY_COLOR }
                  }}>
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>
              </Grid>
            </Box>
            </Grid>
          </Grid>
      </Grid>
      { !isMobile &&
      <Grid container item xs={6} >
          <Grid item xs={12}>
            <div style={{ marginRight: '5%', float: 'center', marginTop: '10%' }}>
              <img src="/images/mind-yourself-mobile-app.png"
                width="95%"
                alt="affinti_logo"
              />
            </div>
        </Grid>
      </Grid>
      }
    </Grid>
    </Grid>
  );
}

SignInContainer.propTypes = {
  isSuccessfullLogin: PropTypes.oneOfType([
    PropTypes.string, PropTypes.bool
  ]),
  setLoginStatus: PropTypes.func,
  setNotificationMsg: PropTypes.func,
  notificationMsg: PropTypes.string,
  setUser: PropTypes.func
}

const mapStateToProps = (state, props) => {
  return {
    isSuccessfullLogin: state.user.isSuccessfullLogin,
    notificationMsg: state.user.notificationMsg,
  }
}

const mapDispatchToProps = {
  setLoginStatus,
  setNotificationMsg,
  setUser
}

export default connect(mapStateToProps, mapDispatchToProps)(SignInContainer);

import * as React from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LoadingButton from '@mui/lab/LoadingButton';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import { Typography } from '@mui/material';
import { Redirect } from 'react-router-dom';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import ReCAPTCHA from 'react-google-recaptcha';
import { RECAPTCHA_KEY } from '../../config';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import PhoneNumberInput from 'mui-phone-number';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

import {
  queryAPI
} from '../../Api';
import {
  Notification
} from '../../Components/Notification';

import {
  PRIMARY_COLOR,
  SECONDARY_COLOR,
  MINDYOURSELF_MAIN_COLOR,
  CANCEL_BUTTON_COLOR
} from '../../constant';

export default function SignUp() {
  const [isLoading, setIsLoading] = React.useState(false);
  const [isSuccessRegister, setIsSuccessRegister] = React.useState(false);
  const [isSubmitted, setIsSubmitted] = React.useState(false)
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const [isRecaptchaProblem, setRecaptchaProblem] = React.useState(false)
  const [isConfirmPassProblem, setConfirmPassProblem] = React.useState(false)
  const recaptchaRef = React.createRef();
  const [password, setPassword] = React.useState("")
  const [confirmPassword, setConfirmPassword] = React.useState("")
  const [isMobile, setIsMobile] = React.useState(false);
  const [phoneNumber, setPhoneNumber] = React.useState('');
  const [industry, setIndustry] = React.useState('');
  const [gender, setGender] = React.useState('');
  const [isEmailProblem, setIsEmailProblem] = React.useState(false)
  const [isPhoneProblem, setIsPhoneProblem] = React.useState(false)

  React.useEffect(() => {
    // Check if user is on a mobile device
    const isMobileDevice = window.matchMedia("screen and (max-width: 768px)").matches;
    setIsMobile(isMobileDevice);
    console.log("isMobileDevice: ", isMobileDevice);
  }, []);

  function is_registered(payload) {
    var status = JSON.parse(JSON.stringify(payload))["status"]
    if (status == 200) {
      setTimeout(() => {
        setIsSuccessRegister(true)
        setIsLoading(false)
        setIsSubmitted(true)
      }, 200)
    }
    else {
      setIsLoading(false)
      setIsSubmitted(true)
      // TODO just check if 'phone number' is in the response
      if (payload.response.data == 'phone number already exists' || 
        payload.response.data == 'Invalid phone number') {
        setIsPhoneProblem(true)
      } else {
        setIsEmailProblem(true)
        document.getElementById("email").value = "";
      }
      setTimeout(() => {
        setIsSubmitted(false)
        setIsPhoneProblem(false)
        setIsEmailProblem(false)
      }, 2000);
    }
  }

  const handleSubmit = (event) => {
    setIsLoading(true)
    event.preventDefault();
    const recaptchaValue = recaptchaRef.current.getValue();
    if (!recaptchaValue) {
      console.log("recaptcha problem")
      setRecaptchaProblem(true)
      setIsLoading(false)
      return;
    }

    if (password !== confirmPassword) {
      console.log("Passwords do not match!")
      setConfirmPassProblem(true)
      setIsLoading(false)
      return;
    } else {setConfirmPassProblem(false)}

    const data = new FormData(event.currentTarget);
    const user_data = {
      first_name: data.get('first_name'),
      last_name: data.get('last_name'),
      email: data.get('email'),
      organisation: data.get('organisation'),
      password: data.get('password'),
      phone_number: data.get('phone_number'),
      industry: industry,
      gender: gender,
      recaptcha_value: recaptchaValue,
    }
    queryAPI('/user', user_data, 'POST')
      .then(payload => {
        is_registered(payload)
      }
      )
  };
  function onChangeRecaptcha(value) {
    console.log("Recaptcha clicked.");
    setRecaptchaProblem(false)
  }
  const handlePhoneNumberChange = (value) => {
    console.log("Phone number changed: ", value);
    setPhoneNumber(value);
  };

  
  return (

    
    <Grid container style={{ minHeight: '100vh' }} backgroundColor={MINDYOURSELF_MAIN_COLOR} >

      {isRecaptchaProblem &&
        <Notification isOpen={true} severity="error" message={"Please confirm you are not a robot"} />
      }
      {isConfirmPassProblem &&
        <Notification isOpen={true} severity="error" message={"Passwords do not match!"} />
      }
      {isSuccessRegister &&
        <Notification isOpen={true} severity="success" message={"Successfully Registered"} />
      }
      {isSubmitted && !isSuccessRegister && isPhoneProblem &&
        <Notification isOpen={true} severity="error" message={"The phone number is either invalid or already exists."} />
      }
      {isSubmitted && !isSuccessRegister && isEmailProblem &&
        <Notification isOpen={true} severity="error" message={"The email is either invalid or already exists."} />
      }

      <Grid container item xs={isMobile ? 12 : 7} align = "center" justify="center">
        <Grid container>
          <Grid item xs={12} sx={{ height: '10%', display: 'flex', alignItems: 'center' }}>
            <img
              src="/images/mindyourself_logo.png"
              width="170px"
              alt="my_logo"
              style={{ float: 'left', marginLeft: '10%' }}
            />
          </Grid>

          <Grid item xs={12} sx={{ height: '90%' }} backgroundColor={MINDYOURSELF_MAIN_COLOR} >
            <Grid item sx={{ width: "80%", float:"center" }} >
            <Box component="form" onSubmit={handleSubmit}  sx={{ mt: 10 }}>
              <Typography
                variant="h2"
                color="white"
                sx={{ float: "left" }}
              >
                Register
              </Typography>
              <KeyboardArrowRightIcon sx={{ 
                float: "left", mt: 1, ml: 1, color: PRIMARY_COLOR,
                ":hover": { color: SECONDARY_COLOR },
                fontSize: '50px'
              }} />
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    autoComplete="firstName"
                    data-testid="firstNameInput"
                    name="first_name"
                    type='text'
                    variant="outlined"
                    required
                    fullWidth
                    id="firstname"
                    placeholder="First name"
                    autoFocus
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    autoComplete="lastName"
                    data-testid="lastNameInput"
                    variant="outlined"
                    type='text'
                    required
                    fullWidth
                    id="lastname"
                    placeholder="Last name"
                    name="last_name"
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    data-testid="emailInput"
                    type='email'
                    required
                    fullWidth
                    id="email"
                    placeholder="Email"
                    name="email"
                    autoComplete="email"
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    data-testid="organisationInput"
                    type='text'
                    fullWidth
                    id="organisation"
                    placeholder="Organisation"
                    name="organisation"
                    autoComplete="organisation"
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    data-testid="passwordInput"
                    required
                    fullWidth
                    name="password"
                    placeholder="Password"
                    aria-label="Password"
                    type={showPassword ? 'text' : 'password'}
                    id="password"
                    autoComplete="current-password"
                    onChange={(e) => setPassword(e.target.value)}
                    InputProps={{
                      endAdornment: 
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>,
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    data-testid="confirmPasswordInput"
                    required
                    fullWidth
                    name="confirmpassword"
                    placeholder="Retype Password"
                    type='password'
                    id="confirmpassword"
                    value={confirmPassword}
                    error={(confirmPassword && confirmPassword !== password) ? true : false}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    helperText="The two passwords should match"
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel id="gender">Gender</InputLabel>
                      <Select
                        id="gender"
                        name='gender'
                        data-testid="genderInput"
                        variant='outlined'
                        fullWidth
                        required
                        label='Gender'
                        value={gender}
                        onChange={(e) => setGender(e.target.value)}
                      >
                        <MenuItem value={"male"}>Male</MenuItem>
                        <MenuItem value={"female"}>Female</MenuItem>
                        <MenuItem value={"other"}>Other</MenuItem>
                      </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel id="industry">Industry</InputLabel>
                      <Select
                        id="industry"
                        data-testid="industryInput"
                        name='industry'
                        labelId="industry"
                        required
                        label="Industry"
                        value={industry}
                        onChange={(e) => setIndustry(e.target.value)}
                      >
                        <MenuItem value={"healthcare"}>Healthcare</MenuItem>
                        <MenuItem value={"aviation"}>Aviation</MenuItem>
                        <MenuItem value={"veterinary medicine"}>Veterinary Medicine</MenuItem>
                        <MenuItem value={"law"}>Law</MenuItem>
                        <MenuItem value={"other"}>Other</MenuItem>
                      </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <PhoneNumberInput
                    id='phone_number'
                    name='phone_number'
                    label="Phone Number"
                    defaultCountry="gb"
                    onChange={handlePhoneNumberChange}
                    value={phoneNumber}
                    inputProps={{
                      required: true,
                    }}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12}>
                  <ReCAPTCHA
                    data-testid="recaptcha"
                    sitekey={RECAPTCHA_KEY}
                    onChange={onChangeRecaptcha}
                    ref={recaptchaRef}
                  />
                </Grid>
              
              </Grid>
              <LoadingButton
                type="submit"
                fullWidth
                variant="contained"
                loading={isLoading}
                sx={{ mt: 3, mb: 2, backgroundColor: PRIMARY_COLOR, height: "45px", color: SECONDARY_COLOR,
                  ":hover": { backgroundColor: SECONDARY_COLOR, color: PRIMARY_COLOR } }}
              >
                Sign Up
              </LoadingButton>
              <Grid container>
                <Grid item xs>
                  <Link href="/login" variant="body2" sx={{ 
                    float: "right", 
                    color: CANCEL_BUTTON_COLOR, 
                    ":hover": { color: SECONDARY_COLOR }
                  }}>
                    Already have an account? Sign in
                  </Link>
                </Grid>
              </Grid>
            </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      { !isMobile &&
        <Grid item xs={5} backgroundColor={MINDYOURSELF_MAIN_COLOR}>
          <Grid item xs={12}>
            <div style={{ marginRight: '5%', float: 'center', marginTop: '10%' }}>
              <img src="/images/mind-yourself-mobile-app.png"
                width="95%"
                alt="my_logo"
              />
            </div>
          </Grid>
        </Grid>
      }
      {
        isSubmitted && isSuccessRegister &&
        <Redirect to={{ pathname: '/login', state: { needs_activation: true } }} />
      }
    </Grid>
  );
}

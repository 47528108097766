import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';

import {
    PRIMARY_COLOR,
    SECONDARY_COLOR,
    MINDYOURSELF_MAIN_COLOR,
} from '../../constant';

export default function ContactContainer(row) {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [isMobile, setIsMobile] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    // Check if user is on a mobile device
    const isMobileDevice = window.matchMedia("screen and (max-width: 768px)").matches;
    setIsMobile(isMobileDevice);
  }, []);

  // prepare the data to show in the table
  const name = row.row.username;
  const email = row.row.email;
  const phone = row.row.phone_number;
  const industry = row.row.industry;
  const focus_area = row.row.focus_area;
  const gender = row.row.gender;
  const time_preference = row.row.time_preference;
  const request_time = row.row.request_time;
  const contact_mode = row.row.contact_mode;

  const process_whatsapp_phone_number = () => {
    // remove all spaces, dashes, brackets, and plus signs
    var phone_number = phone.replace(/\s/g, '');
    phone_number = phone_number.replace(/-/g, '');
    phone_number = phone_number.replace(/\(/g, '');
    phone_number = phone_number.replace(/\)/g, '');
    phone_number = phone_number.replace(/\+/g, '');
    
    return "https://wa.me/" + phone_number;
  }

  return (
    <div>
      <VisibilityIcon 
        data-testid="visibility-icon"
        style={{ color: MINDYOURSELF_MAIN_COLOR }}
        onClick={handleClickOpen}
        onMouseEnter={(event) => event.target.style.cursor = "pointer"}
        />
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Details about " + name + ""}
          <IconButton
            data-testid="close-button"
            edge="end"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            sx={{ position: 'absolute', right: 8, top: 8, mt:1, mr:1 }}
            >
                <CloseIcon />
            </IconButton>
        </DialogTitle>
        <DialogContent>
            {/* present data in a vertical table */}
            <DialogContentText>
            <Table sx={{ minWidth: isMobile ? '100%' : 500, maxWidth: isMobile ? '100%' : 500 }} aria-label="simple table" >
                <TableBody>
                    <TableRow
                        key={name}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell component="th" scope="row" sx={{ fontWeight: 'bold' }}>
                            Name
                        </TableCell>
                        <TableCell align="right">{name}</TableCell>
                    </TableRow>
                    <TableRow
                        key={email}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell component="th" scope="row" sx={{ fontWeight: 'bold' }}>
                            Email
                        </TableCell>
                        <TableCell align="right">{email}</TableCell>
                    </TableRow>
                    <TableRow
                        key={phone}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell component="th" scope="row" sx={{ fontWeight: 'bold' }}>
                            Phone
                        </TableCell>
                        <TableCell align="right">{phone}</TableCell>
                    </TableRow>
                    <TableRow
                        key={industry}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell component="th" scope="row" sx={{ fontWeight: 'bold' }}>
                            Industry
                        </TableCell>
                        <TableCell align="right">{industry}</TableCell>
                    </TableRow>
                    <TableRow
                        key={focus_area}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell component="th" scope="row" sx={{ fontWeight: 'bold' }}>
                            Focus Area
                        </TableCell>
                        <TableCell align="right">{focus_area}</TableCell>
                    </TableRow>
                    <TableRow
                        key={gender}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell component="th" scope="row" sx={{ fontWeight: 'bold' }}>
                            Gender
                        </TableCell>
                        <TableCell align="right">{gender}</TableCell>
                    </TableRow>
                    { isMobile && 
                      <TableRow
                        key={request_time}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell component="th" scope="row" sx={{ fontWeight: 'bold' }}>
                            Request Time
                        </TableCell>
                        <TableCell align="right">{request_time}</TableCell>
                      </TableRow>
                    }
                    { isMobile && 
                      <TableRow
                        key={time_preference}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell component="th" scope="row" sx={{ fontWeight: 'bold' }}>
                          Time preference
                        </TableCell>
                        <TableCell align="right">{time_preference}</TableCell>
                      </TableRow>
                    }
                    
                </TableBody>
            </Table></DialogContentText>
         

        </DialogContent>
        <DialogActions sx={{ ml:2, mr:2, mb:1 }}>
          <Button onClick={handleClose} 
            disabled={contact_mode === "whatsapp" ? false : true}
            href={process_whatsapp_phone_number()}
            target="_blank"
            sx={{ backgroundColor: PRIMARY_COLOR, 
                    color: SECONDARY_COLOR, 
                    ":hover": { backgroundColor: SECONDARY_COLOR, color: PRIMARY_COLOR }
                }}
            >
            Start conv
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

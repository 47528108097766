import * as React from 'react';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import { Typography } from '@mui/material';
import { useLocation, useHistory } from 'react-router-dom';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import {
  queryAPI
} from '../../Api';
import {
  Notification
} from '../../Components/Notification';
import {
  setNotificationMsg,
} from '../../redux/User/user.action';

import {
  PRIMARY_COLOR,
  SECONDARY_COLOR,
  MINDYOURSELF_MAIN_COLOR,
} from '../../constant';

function RestPasswordContainer({ setNotificationMsg, notificationMsg }) {
  const [isLoading, setIsLoading] = React.useState(false)
  const [isSubmitted, setIsSubmitted] = React.useState(false)
  const [isSuccessSubmit, setIsSuccessSubmit] = React.useState(false)
  const [value, setValue] = React.useState('');
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const [confirmPassword, setConfirmPassword] = React.useState("")

  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const reset_token = params.get("t") 
  const [isMobile, setIsMobile] = React.useState(false);
  React.useEffect(() => {
    // Check if user is on a mobile device
    const isMobileDevice = window.matchMedia("screen and (max-width: 768px)").matches;
    setIsMobile(isMobileDevice);
    console.log("isMobileDevice: ", isMobileDevice);
  }, []);

  let history = useHistory();
  function handleClickLogo(){
    history.push("/login")}

  React.useEffect(() => {
    if (isSuccessSubmit) {
      setTimeout(() => {history.push('/login')}, 2000);
    }
  }, [isSuccessSubmit, history]);

  const handleSubmit = (event) => {
    setIsLoading(true)
    event.preventDefault();
    setIsSubmitted(true);
    setValue('');
    const data = new FormData(event.currentTarget);
    const user_data = {
      reset_token: reset_token,
      new_password: data.get('newpassword'),
    }
    queryAPI('/reset-password', user_data, 'POST')
      .then(response => {
        if(response.message === 'Network Error') {
          setNotificationMsg(response.message)
          setIsLoading(false)
          setIsSubmitted(true)
          setIsSuccessSubmit(false)
          setTimeout(() => {setIsSubmitted(false)}, 2000);
          return
        }
        if (response && response.status !== 200) {
          setNotificationMsg("Unable to reset password")
          setIsSubmitted(true)
          setIsSuccessSubmit(false)
          setTimeout(() => {setIsSubmitted(false)}, 2000);
        } else {
          setNotificationMsg("Password reset successfully.")
          setIsSubmitted(true)
          setIsSuccessSubmit(true)
        }
        setIsLoading(false)
      })
  };
  return (
    <Grid container style={{ minHeight: '100vh' }} backgroundColor={MINDYOURSELF_MAIN_COLOR}>
    <Grid container style={{ minHeight: '100vh' }}>
      {
        isSuccessSubmit && 
        <Notification
          severity="success"
          isOpen={true}
          message={notificationMsg || "Password reset successfully."} />
      }
      { !isLoading && isSubmitted && !isSuccessSubmit &&
        <Notification
          severity="error"
          isOpen={true}
          message={notificationMsg || "Reset Failed"} />
      }

      <Grid container item xs={isMobile ? 12 : 6} align = "center" justify="center">
        <Grid container>
          <Grid item xs={12} sx={{ height: '10%', display: 'flex', alignItems: 'center' }}>
            <img
              src="/images/mindyourself_logo.png"
              width="170px"
              alt="my_logo"
              style={{ float: 'left', marginLeft: '10%' }}
              onClick={handleClickLogo}
            />
          </Grid>
          <Grid item xs={12} sx={{ height: '5%' }} />
          <Grid item xs={12} sx={{ height: '85%' }} >
          <Grid item xs={12} sx={{ width: "80%", float:"center" }}>
            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 10 }}>
              <Typography
                variant="h2"
                color="white"
                sx={{ float: "left" }}
              >
                Reset Password
              </Typography>
              <KeyboardArrowRightIcon sx={{ 
                float: "left", mt: 1, ml: 1, color: PRIMARY_COLOR,
                ":hover": { color: SECONDARY_COLOR },
                fontSize: '50px'
              }} />
              
              <TextField
                margin="normal"
                required
                fullWidth
                name="newpassword"
                placeholder='New Password'
                data-testid="resetPasswordInput"
                type={showPassword ? 'text' : 'password'}
                id="newpassword"
                value={value}
                onChange={(newValue) => {
                  setValue(newValue.target.value);
                }}
                autoFocus
                InputProps={{
                  endAdornment: 
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>,
                }}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="retypepassword"
                placeholder='Retype Password'
                data-testid="resetConfirmationInput"
                type='password'
                id="retypepassword"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                error={(confirmPassword && confirmPassword !== value) ? true : false}
              />
              <LoadingButton
                type="submit"
                fullWidth
                variant="contained"
                loading={isLoading}
                sx={{ mt: 3, mb: 2, backgroundColor: PRIMARY_COLOR, height: "45px", color: SECONDARY_COLOR,
                  ":hover": { backgroundColor: SECONDARY_COLOR, color: PRIMARY_COLOR } }}
              >
                Reset
              </LoadingButton>
              <Grid container>
                <Grid item>
                  <Link href="/register" variant="body2" sx={{ 
                    float: "left", 
                    color: PRIMARY_COLOR, 
                    ":hover": { color: SECONDARY_COLOR }
                  }}>
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          </Grid>
        </Grid>
      </Grid>
      { !isMobile &&
      <Grid container item xs={6} >
          <Grid item xs={12}>
            <div style={{ marginRight: '5%', float: 'center', marginTop: '10%' }}>
              <img src="/images/mind-yourself-mobile-app.png"
                width="95%"
                alt="affinti_logo"
              />
            </div>
        </Grid>
      </Grid>
      }
      </Grid>
    </Grid>
  );
}

RestPasswordContainer.propTypes = {
  setNotificationMsg: PropTypes.func,
  notificationMsg: PropTypes.string,
}

const mapStateToProps = (state, props) => {
  return {
    notificationMsg: state.user.notificationMsg,
  }
}

const mapDispatchToProps = {
  setNotificationMsg,
}

export default connect(mapStateToProps, mapDispatchToProps)(RestPasswordContainer);

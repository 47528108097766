import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

export const PRIMARY_COLOR = "#96e1bb";
export const SECONDARY_COLOR = "#084444";
export const PRIMARY_TEXT_COLOR = "#000000";
export const USER_UTTERANCE_BUTTON = '#e5ebe0';
export const SYSTEM_UTTERANCE_BUTTON = "#e5ebe0";
export const MINDYOURSELF_MAIN_COLOR = "#449798";

export const CANCEL_BUTTON_COLOR = '#E5E4E2';
//export const PRIMARY_TEXT_COLOR = "#fff";
//export const USER_UTTERANCE_BUTTON = "#e0e0eb";
//export const SYSTEM_UTTERANCE_BUTTON = "#e6f3ff";

export const CARD_MAX_WIDTH = {
  maxWidth: 900
}

export const ITEM_COMPONENT = styled(Paper)(({ theme }) => ({
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: 'center',
	color: theme.palette.text.secondary,
}));

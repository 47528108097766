import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import FormControlLabel from '@mui/material/FormControlLabel';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import SchedulingContainer from '../SchedulingContainer';
import { queryAPI } from '../../Api';

import {
    PRIMARY_COLOR,
    SECONDARY_COLOR,
    MINDYOURSELF_MAIN_COLOR,
    CANCEL_BUTTON_COLOR
} from '../../constant';
import { Grid } from '@mui/material';


export default function TimePickerContainer() {
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [isMobile, setIsMobile] = React.useState(false);
    const [isAddSlotOpen, setIsAddSlotOpen] = React.useState(false);
    const [sendAvailabilityToDatabase, setSendAvailabilityToDatabase] = React.useState(0);
    const [openAvailabilityAlert, setOpenAvailabilityAlert] = React.useState(false);
    const [dictSelectedAvailabilities, setDictSelectedAvailabilities] = React.useState([]);
    const [confirmOpen, setConfirmOpen] = React.useState(false);
    const [date, setDate] = React.useState(null);
    const [timeStart, setTimeStart] = React.useState(null);
    const [timeEnd, setTimeEnd] = React.useState(null);
    const [sendToBackend, setSendToBackend] = React.useState(false);
    
    const handleHookValueChange = (value) => {
        setDate(value["date"]);
        setTimeStart(value["timeStart"]);
        setTimeEnd(value["timeEnd"]);
      };
    
    React.useEffect(() => {
        var currentDict = [date, timeStart,timeEnd]
        if (currentDict.every((item) => item != null && item != undefined && item != "")) {
            var st = new Date(timeStart);
            var et = new Date(timeEnd);
            if (st < et) {
                setDictSelectedAvailabilities([
                    ...dictSelectedAvailabilities, currentDict]);
                }
            }
            console.log("in dictSelectedAvailabilities: ", dictSelectedAvailabilities)
            console.log("in components: ", components)
        }, [date, timeStart, timeEnd]);
    
    React.useEffect(() => {
        if (sendToBackend) {
            if (dictSelectedAvailabilities.length == 0) {
                setSendAvailabilityToDatabase(-2)
                setTimeout(() => { setSendAvailabilityToDatabase(0) }, 1000);
            } else {
                console.log("sending availabilities to database...");
                const data = {
                    user_id: localStorage.getItem('id'),
                    availability: { availabilities: dictSelectedAvailabilities }
                };
                console.log(data);
                setTimeout(() => { setSendToBackend(false) }, 1000);
                //handleClose();
                console.log(data);
                queryAPI('/user', data, 'PUT')
                    .then((response) => {
                        if (response.status == 200) {
                            console.log("availabilities saved to database");
                        } else {
                            console.log("error saving availabilities to database");
                        }
                        setSendToBackend(false)
                        setDictSelectedAvailabilities([]);
                        handleClose();
                    })
                    }
        }
    }, [sendToBackend]);

    const availabilitySaved = () => {
        setSendToBackend(true);
        setSendAvailabilityToDatabase(1)
        setTimeout(() => { setSendAvailabilityToDatabase(0) }, 1000);
        setTimeout(() => { setSendToBackend(false) }, 1000);
    }

    const [components, setComponents] = React.useState([]);
    const [idCounter, setIdCounter] = React.useState(components.length);

    const handleAdd = () => {
        setComponents(prevComponents => [
        ...prevComponents,
        { id: idCounter, value: `Component ${idCounter}` }
        ]);
        setIdCounter(prevIdCounter => prevIdCounter + 1);
    };

    const handleDelete = id => {
        setComponents(prevComponents => prevComponents.filter(comp => comp.id !== id));
        setDictSelectedAvailabilities(
            dictSelectedAvailabilities.filter((item, index) => index !== id)
        )
        // remove a component from components
        const newArray = [...components]; 
        newArray.pop();
        setComponents(newArray);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setIsAddSlotOpen(false);
        setComponents([]);
        setConfirmOpen(false);
    };

    React.useEffect(() => {
        // Check if user is on a mobile device
        const isMobileDevice = window.matchMedia("screen and (max-width: 768px)").matches;
        setIsMobile(isMobileDevice);
    }, []);


    const handleCloseAlert = () => {
        setOpenAvailabilityAlert(false)
    }

    const handleStay = () => {
        setConfirmOpen(false);
      };
    const handleCloseConfirm = () => {
        setConfirmOpen(true);
    };

    return (
        <div>
        <CalendarMonthIcon 
            data-testid="visibility-icon"
            style={{ color: SECONDARY_COLOR }}
            onClick={handleClickOpen}
            onMouseEnter={(event) => event.target.style.cursor = "pointer"}
            />
        <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose={handleCloseConfirm}
            aria-labelledby="responsive-dialog-title"
            sx={{ minHeight: 500, maxHeight: 700 }}
        >
            {sendAvailabilityToDatabase == 1 &&
                <Snackbar 
                    open={true} 
                    sx={{ width: '30%' }} 
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }} 
                >
                    <Alert 
                        onClose={handleCloseAlert}
                        severity="success" 
                        sx={{ width: '100%', height: '70px', display: 'flex', alignItems: 'center', 
                            fontSize: '15px', marginTop: '30px' }}
                    >
                        Availability saved.
                    </Alert>
                </Snackbar>
            }
            {sendAvailabilityToDatabase == -1 &&
                <Snackbar 
                    open={true} 
                    sx={{ width: '30%' }} 
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }} 
                >
                    <Alert 
                        onClose={handleCloseAlert}
                        severity="warning" 
                        sx={{ width: '100%', height: '70px', display: 'flex', alignItems: 'center', 
                            fontSize: '15px', marginTop: '30px' }}
                    >
                        Availability not saved.
                    </Alert>
                </Snackbar>
            }
            {sendAvailabilityToDatabase == -2 &&
                <Snackbar 
                    open={true} 
                    sx={{ width: '30%' }} 
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }} 
                >
                    <Alert 
                        onClose={handleCloseAlert}
                        severity="warning" 
                        sx={{ width: '100%', height: '70px', display: 'flex', alignItems: 'center', 
                            fontSize: '15px', marginTop: '30px' }}
                    >
                        No availabilities to save.
                    </Alert>
                </Snackbar>
            }
            <DialogTitle id="responsive-dialog-title" 
                sx={{ minWidth: 600, 
                    display: 'flex',

                    mb: 2,
                    backgroundColor: MINDYOURSELF_MAIN_COLOR,
                    fontFamily: 'Lato',
                    color: SECONDARY_COLOR,
                 }}
            >
                Pick your availabilities (Europe/London Timezone)
                <IconButton
                    data-testid="close-button"
                    edge="end"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                    sx={{ position: 'absolute', right: 8, top: 5, mt:1, mr:1 }}
                    >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            
            <DialogContent>
                <DialogContentText>
                    {components.map(component => (
                        <SchedulingContainer 
                            key={component.id}
                            onDelete={() => handleDelete(component.id)}
                            onHookValueChange={handleHookValueChange}/>
                    ))}   
                </DialogContentText>
            </DialogContent>

            <DialogActions sx={{ ml:2, mr:2, mb:1 , justifyContent: 'space-between' }}>
            <Grid>
                <FormControlLabel 
                    label="Add time slot"
                    labelPlacement='start'
                    control={ 
                        <AddCircleIcon
                            data-testid="visibility-icon"
                            style={{ 
                                color: isAddSlotOpen ? "#ececec" : MINDYOURSELF_MAIN_COLOR,
                                marginLeft: 10 }}
                            disabled={isAddSlotOpen}
                            onClick={handleAdd}
                            onMouseEnter={(event) => event.target.style.cursor = "pointer"}
                        /> } 
                />
            </Grid>
            <Button 
                onClick={availabilitySaved}
                data-testid="next-button"
                sx={{ backgroundColor: PRIMARY_COLOR, 
                        color: SECONDARY_COLOR, 
                        ":hover": { backgroundColor: SECONDARY_COLOR, color: PRIMARY_COLOR }
                    }}
                >
                Save
            </Button>
            </DialogActions>
        </Dialog>

        <Dialog
            open={confirmOpen}
            onClose={handleStay}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{"Discard Changes?"}</DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
                Are you sure you want to exit without saving?
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={handleStay}
                sx={{ backgroundColor: CANCEL_BUTTON_COLOR,
                        color: SECONDARY_COLOR }}
                autoFocus>
                Cancel
            </Button>
            <Button onClick={handleClose}
                sx={{ backgroundColor: PRIMARY_COLOR,
                        color: SECONDARY_COLOR,
                        ":hover": { backgroundColor: SECONDARY_COLOR, color: PRIMARY_COLOR } }}>
                Confirm
            </Button>
            </DialogActions>
        </Dialog>
        </div>
    );
}

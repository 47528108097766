import * as React from 'react';
import PropTypes from 'prop-types';
import MuiAppBar from '@mui/material/AppBar';
import { styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import { useHistory } from "react-router-dom";
import CssBaseline from '@mui/material/CssBaseline';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Box from '@mui/material/Box';
import Zoom from '@mui/material/Zoom';
import IconButton from '@mui/material/IconButton';
import LogoutIcon from '@mui/icons-material/Logout';
import Tooltip from '@mui/material/Tooltip';
import AppBarMenu from '../AppBarMenuContainer';
import { Grid } from '@mui/material';
import TimePickerContainer from '../TimePickerContainer';

import {
  PRIMARY_TEXT_COLOR,
  MINDYOURSELF_MAIN_COLOR,
} from '../../constant';

function ScrollTop(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      '#back-to-top-anchor',
    );

    if (anchor) {
      anchor.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  };

  return (
    <Zoom in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: 'fixed', bottom: 16, right: 16 }}
      >
        {children}
      </Box>
    </Zoom>
  );
}

ScrollTop.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

const drawerWidth = 280;
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));


export default function BackToTop(props) {
  const [isMobile, setIsMobile] = React.useState(false);

  React.useEffect(() => {
    // Check if user is on a mobile device
    const isMobileDevice = window.matchMedia("screen and (max-width: 768px)").matches;
    setIsMobile(isMobileDevice);
  }, []);


  let history = useHistory();
  function handleClickLogo(){
    history.push("/home")}
  return (
    <React.Fragment>
      <CssBaseline />

      <AppBar sx={{ paddingLeft:isMobile?3:5, paddingRight:isMobile?3:5, bgcolor: MINDYOURSELF_MAIN_COLOR }} data-testid="app-bar">
        <Toolbar>

          <Grid container data-testid="logo" sx={{ 
            height: '10%', marginLeft: isMobile ? 0:2, marginRight: isMobile ? 0:2, display: 'flex', alignItems: 'center' }}>
            <Grid item xs={isMobile ? 7 : 10} sx={{ float: 'left', height: '10%' }}>
              <img
                src="/images/mindyourself_logo.png"
                width="140px"
                alt="my_logo"
                style={{  cursor: "pointer", float: 'left' }}
                onClick={handleClickLogo}
              />
            </Grid>

            <Grid item xs={isMobile ? 5 : 2} spacing={2} sx={{ justifyContent: 'flex-end', display: 'flex', alignItems: 'center' }}>
              <Tooltip title="Availability">
                <Grid sx={{mt:1}}>
                  <TimePickerContainer />
                </Grid>
              </Tooltip>
              <AppBarMenu />
              <Tooltip title="logout" data-testid="logout-button">
                <IconButton
                  sx={{ color: PRIMARY_TEXT_COLOR }}
                  color="inherit"
                  onClick={() => props.logout()}>
                  <LogoutIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>

        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
}

BackToTop.propTypes = {
  logout: PropTypes.func,
  open: PropTypes.bool,
  toggleDrawer: PropTypes.func,
  isDashBoardPage: PropTypes.bool,
}
import * as React from 'react';
import PropTypes from 'prop-types';
import ProfileContainer from '../ProfileContainer';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PersonIcon from '@mui/icons-material/Person';

import {
    PRIMARY_COLOR,
    SECONDARY_COLOR,
  } from '../../constant';

export default function AppBarMenu() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <Button
                id="positioned-button"
                aria-controls={open ? 'positioned-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                <PersonIcon alt="User name" data-testid = "profile-img-test-id" 
                    sx={{ fontSize: 30, color: SECONDARY_COLOR, ":hover": { color: PRIMARY_COLOR } }} />
            </Button>
            <Menu
                id="positioned-menu"
                aria-labelledby="positioned-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <MenuItem >
                    <ProfileContainer />                    
                </MenuItem>

            </Menu>
        </div>
    );
}

AppBarMenu.propTypes = {
    userDetails: PropTypes.array,
}

// const mapStateToProps = (state, props) => {
//     return {
// 		userDetails: getCurrentUserDetails(state['user']),
//     }
// }

//export default connect(mapStateToProps, null)(AppBarMenu);
import React from 'react';
import { styled, ThemeProvider, createTheme } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import AppBarContainer from '../../Container/AppBarContainer';
import ContactContainer from '../../Container/ContactContainer';
import NotesContainer from '../../Container/NotesContainer';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import EditNoteIcon from '@mui/icons-material/EditNote';
import Switch from '@mui/material/Switch';
import { alpha } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import CircleIcon from '@mui/icons-material/Circle';
import { Redirect } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';

import { Typography } from '@mui/material';

import {
    PRIMARY_COLOR,
    SECONDARY_COLOR,
    MINDYOURSELF_MAIN_COLOR,
} from '../../constant';

import {
    isLoggedIn,
} from '../../utils';
import {
    queryAPI
} from '../../Api';


const drawerWidth = 280;
const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        '& .MuiDrawer-paper': {
            backgroundColor: PRIMARY_COLOR,
            position: 'relative',
            paddingLeft: '10%',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(7),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(9),
                },
            }),
            // add background image
            backgroundImage: `url("/images/bg.png")`
        },
    }),
);

const MindYourselfSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: MINDYOURSELF_MAIN_COLOR,
      '&:hover': {
        backgroundColor: alpha(SECONDARY_COLOR, theme.palette.action.hoverOpacity),
      },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: PRIMARY_COLOR,
    },
  }));

const mdTheme = createTheme();

function is_allowed(is_allowed_to_contact) {
    if (is_allowed_to_contact) {
        return <CircleIcon style={{ color: "green" }} />
    } else {
        return <CircleIcon style={{ color: "red" }} />
    }
}

function show_details(row) {
    return <ContactContainer row={row} />
}

function show_notes(row) {
    row["is_existing_note"] = false;
    return <NotesContainer row={row} />
}



function Home() {
    const [loggedIn, setIsLoggedIn] = React.useState(isLoggedIn());
    const [isMobile, setIsMobile] = React.useState(false);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [totalNumberOfRequests, setTotalNumberOfRequests] = React.useState(0);
    const [data, setData] = React.useState([]);
    const [isOnline, setIsOnline] = React.useState(true);
    const [recentNotesList, setRecentNotesList] = React.useState([]);
    const [openAvailabilityAlert, setOpenAvailabilityAlert] = React.useState(false);
    const [phoneForSessions, setPhoneForSessions] = React.useState(null);
    const [previousSessions, setPreviousSessions] = React.useState(null);
    const [openPreviousSessions, setOpenPreviousSessions] = React.useState(false);
    const [previousSessionsTitle, setPreviousSessionsTitle] = React.useState("Previous sessions:");
    
    // get data
    React.useEffect(() => {
        var pagination_data = {page: page, rowsPerPage: rowsPerPage }
        queryAPI('/requests?user_id='+localStorage.getItem('id'), pagination_data, 'GET')
            .then(
                (response) => {
                    if (response.status === 200) {
                        console.log(response.data)
                        // add is_allowed_to_contact: true to each request
                        response.data.requests.forEach(function (item) {
                            // if request_time + timing_preference > now then is_allowed_to_contact = true
                            var request_time = new Date(Date.parse(item["request_time"]));
                            var number_of_hours = Number(item["timing_preference"]);
                            var limit_time = request_time.setHours(request_time.getHours() + number_of_hours);
                            var now = new Date();
                            if (now < limit_time) {
                                item["is_allowed_to_contact"] = true;
                            } else { item["is_allowed_to_contact"] = false; }
                            
                            item["timing_preference"] = "within "+item["timing_preference"]+" hours";

                            // add iterative id for each request
                            item["id"] = response.data.requests.indexOf(item) + 1;

                            // set total number of requests
                            setTotalNumberOfRequests(response.data.total_requests);

                        });
                        setData(response.data.requests);
                    } else {
                        console.log("error getting requests")
                    }
                }
            ).catch((error) => {
                console.log(error);
            });
        }
    , [page, rowsPerPage]);

      

    React.useEffect(() => {
        // Check if user is on a mobile device
        const isMobileDevice = window.matchMedia("screen and (max-width: 768px)").matches;
        setIsMobile(isMobileDevice);
    }, []);

    // React.useEffect(() => {
    //     // send put request to update online status
    //     var data = {
    //         "user_id": localStorage.getItem('id'),
    //         "is_online": isOnline}
    //     queryAPI('/user', data, 'PUT')
        
    // }, [isOnline]);

    const handleChangeSwitch = (event) => {
        setIsOnline(event.target.checked);
        console.log(isOnline)
    };

    const logout = () => {
        console.log("logged out")
        queryAPI('/token', '', 'DELETE')
        localStorage.removeItem('auth_token')
        setIsLoggedIn(false)
    }

    const styles = {
        '& > .MuiTypography-root': {
            fontWeight: 'bold'
        }
    };

    const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

    const handleCloseAlert = () => {
        setOpenAvailabilityAlert(false)
    }

    React.useEffect(() => {
        if (phoneForSessions == null) { return }
        queryAPI('/session?user_id='+localStorage.getItem('id')+'&all_by_phone='+phoneForSessions, '', 'GET')
            .then((response) => {
                if (response.status === 200) {
                    console.log("sessions: ", response.data.sessions)
                    setPreviousSessions(response.data.sessions)
                    if (response.data.sessions.length == 0) {
                        setPreviousSessionsTitle("No previous sessions for this user.")
                    } else {
                        setPreviousSessionsTitle("Previous sessions:")
                    }
                } else {
                    console.log("error getting sessions")
                    setPreviousSessions(null)
                }
                setPhoneForSessions(null)
            }).catch((error) => {
                console.log(error);
                setPhoneForSessions(null)
            });
    }, [phoneForSessions, openPreviousSessions])

    // get recent notes
    React.useEffect(() => {
        queryAPI('/recent-notes?user_id='+localStorage.getItem('id'), '', 'GET'
        ).then((response) => {
            if (response.status === 200) {
              const rec_notes = response.data.notes;
              setRecentNotesList(rec_notes)
            } else {
              console.log("error getting recent notes")
            }
          }).catch((error) => {
            console.log(error);
          });
        }
    , []);
    
    // get availability from backend
    React.useEffect(() => {
        queryAPI('/user?user_id='+localStorage.getItem('id'),'', 'GET')
        .then((response) => {
            if (response.status === 200) {
                if (response.data.availability == null) {
                    setOpenAvailabilityAlert(true)
                    setTimeout(() => {
                        setOpenAvailabilityAlert(false)}, 10000)
            }}
        })
        .catch((error) => {
            console.log(error)
        })
    }, [])
    
    return (
        <ThemeProvider theme={mdTheme}>
            {
                !isLoggedIn() && <Redirect to='/login' />
            }
 
            <Box sx={{ display: 'flex' }}>
                <AppBarContainer logout={logout} insights={true} />
                {openAvailabilityAlert &&
                    <Snackbar 
                        open={true} 
                        sx={{ width: '90%' }} 
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }} 
                    >
                        <Alert 
                            onClose={handleCloseAlert}
                            severity="info" 
                            sx={{ width: '100%', height: '70px', display: 'flex', alignItems: 'center', 
                                fontSize: '15px', marginTop: '30px' }}
                        >
                            Please fill in your availabilities in the <b>Availability</b> menu.
                        </Alert>
                    </Snackbar>
                }
                { !isMobile &&
                    <Drawer variant="permanent" open={true} >
                        <Toolbar
                            sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            px: [1],
                            }}
                        >
                            <IconButton style={{ color: PRIMARY_COLOR }}>
                            <ChevronLeftIcon />
                            </IconButton>
                        </Toolbar>
                        <Divider />
                        <Typography variant="h6" component="div" 
                            sx={{ textAlign: 'left', color: SECONDARY_COLOR, fontWeight: 'bold', mt: 2 }}>
                            Recent notes:
                        </Typography>
                        <List>
                            {recentNotesList.map((item, index) => (
                                <ListItem button key={index} >
                                <ListItemIcon style={{ color: MINDYOURSELF_MAIN_COLOR }}>
                                    <EditNoteIcon />
                                </ListItemIcon>
                                <ListItemText primary={item.appuser_name} 
                                    style={{ color: MINDYOURSELF_MAIN_COLOR }}
                                    sx={styles}
                                    />
                                <NotesContainer row={{
                                    "is_existing_note": true, 
                                    "username": item.appuser_name,
                                    "notes": item.notes
                                    }} />
                                </ListItem>
                            ))}
                        </List>
                    </Drawer>
                }
                { openPreviousSessions && previousSessions != null &&
                    <Dialog
                        open={openPreviousSessions}
                        onClose={() => {setOpenPreviousSessions(false)}}
                    >
                        <DialogTitle id="alert-dialog-title">{previousSessionsTitle}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                
                                        <Table sx={{ minWidth: isMobile ? '100%' : 300, maxWidth: isMobile ? '100%' : 500 }} aria-label="simple table" >
                                            <TableBody>
                                            {previousSessions.map((item, index) => (
                                                <TableRow
                                                    key={index}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell component="th" scope="row" sx={{ fontWeight: 'bold' }}>
                                                        Session {index+1}
                                                    </TableCell>
                                                    <TableCell align="right">{item}</TableCell>
                                                </TableRow>
                                            ))}
                                            </TableBody>
                                        </Table>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => {setOpenPreviousSessions(false)}} autoFocus>
                                Close
                            </Button>
                        </DialogActions>
                    </Dialog>
                }

                <Box
                    component="main"
                    sx={{
                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto',
                    }}
                >
                    <Toolbar />
                    
                    <Container maxWidth="lg"
                        sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}
                    >
                        <FormControlLabel label= {isOnline ? "online" : "offline"} 
                            sx={{ mt: 2, pr: isMobile?4:8 }}
                            control={ 
                                <MindYourselfSwitch 
                                    checked={isOnline}
                                    onChange={handleChangeSwitch}
                                /> } 
                        />
                    </Container>

                    <Container maxWidth="lg" sx={{ mt: 5 }}>
                        <Typography variant="h5" component="div"
                            sx={{ textAlign: 'left', color: SECONDARY_COLOR, fontWeight: 'bold', mt: 2, ml:4, mb:isMobile?2:0 }}>
                            Requests assigned to you:
                        </Typography>
                    </Container>


                    <Container maxWidth="lg">
                    <Box bgcolor="white" p={isMobile ? 0:2} m={isMobile ? 0:2} >
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ fontWeight: "bold" }} align="center"></TableCell>
                                    <TableCell style={{ fontWeight: "bold" }} align="center">Username</TableCell>
                                    {!isMobile && <TableCell style={{ fontWeight: "bold" }} align="center">Gender</TableCell>}
                                    {!isMobile && <TableCell style={{ fontWeight: "bold" }} align="center">Request time</TableCell>}
                                    {!isMobile && <TableCell style={{ fontWeight: "bold" }} align="center">Time preference</TableCell>}
                                    <TableCell style={{ fontWeight: "bold" }} align="center">Contact mode</TableCell>
                                    <TableCell style={{ fontWeight: "bold" }} align="center">Contact</TableCell>
                                    <TableCell style={{ fontWeight: "bold" }} align="center">Notes</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.map((row) => (
                                    <TableRow key={row.id}>
                                        <TableCell align="center">{is_allowed(row.is_allowed_to_contact)}</TableCell>
                                        <TableCell align="center">
                                            <Button 
                                                style={{ textTransform: 'none', color: MINDYOURSELF_MAIN_COLOR }}
                                                onClick={() => {setPhoneForSessions(row.phone_number); setOpenPreviousSessions(true)}}
                                            >{row.username}</Button>
                                            </TableCell>
                                        {!isMobile && <TableCell align="center">{row.gender}</TableCell>}
                                        {!isMobile && <TableCell align="center">{row.request_time}</TableCell>}
                                        {!isMobile && <TableCell align="center">{row.timing_preference}</TableCell>}
                                        <TableCell align="center">{row.contact_mode}</TableCell>
                                        <TableCell align="center">{show_details(row)}</TableCell>
                                        <TableCell align="center">{show_notes(row)}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={totalNumberOfRequests}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Box>
                    </Container>

                </Box>
            </Box>

        </ThemeProvider>
    );
}

export default Home;

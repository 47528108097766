import * as React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import DeleteIcon from '@mui/icons-material/Delete';
import AddTaskIcon from '@mui/icons-material/AddTask';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
// Use plugins
dayjs.extend(utc);
dayjs.extend(timezone);

import {
    MINDYOURSELF_MAIN_COLOR,
} from '../../constant';
import { Grid } from '@mui/material';


export default function SchedulingContainer({ onHookValueChange, onDelete }) {
    const [isMobile, setIsMobile] = React.useState(false);
    const [selectedDate, setSelectedDate] = React.useState(null);
    const [selectedTimeStart, setSelectedTimeStart] = React.useState(null);
    const [selectedTimeEnd, setSelectedTimeEnd] = React.useState(null);
    const [availabilities, setAvailabilities] = React.useState({});
    const [isAdded, setIsAdded] = React.useState(false);
    const [openWarning, setOpenWarning] = React.useState(false);

    React.useEffect(() => {
        // update availabilities
        if (selectedDate && selectedTimeStart && selectedTimeEnd) {
            setAvailabilities({
                date: dayjs(selectedDate).tz("Europe/London").format(),
                timeStart: dayjs(selectedTimeStart).tz("Europe/London").format(),
                timeEnd: dayjs(selectedTimeEnd).tz("Europe/London").format(),
            })
        } else {
            setOpenWarning(true);
            setTimeout(() => {setOpenWarning(false), 1000});
        }

    }, [isAdded]);

    React.useEffect(() => {
        onHookValueChange(availabilities);
    }, [availabilities]);

    const handleTimeStartChange = (time) => {
        setSelectedTimeStart(time);
    };
    const handleTimeEndChange = (time) => {
        setSelectedTimeEnd(time);
    };
    const handleDateChange = (date) => {
        setSelectedDate(date);
    };


    React.useEffect(() => {
        // Check if user is on a mobile device
        const isMobileDevice = window.matchMedia("screen and (max-width: 768px)").matches;
        setIsMobile(isMobileDevice);
    }, []);

    
    return (
        <div>
            {openWarning &&
                <Snackbar 
                    open={true} 
                    sx={{ width: '30%' }} 
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }} 
                >
                    <Alert 
                        onClose={() => setOpenWarning(false)}
                        severity="warning" 
                        sx={{ width: '100%', height: '70px', display: 'flex', alignItems: 'center', 
                            fontSize: '15px', marginTop: '30px' }}
                    >
                        Please select a date and time.
                    </Alert>
                </Snackbar>
            }
            <Grid container direction="row" alignItems="center" justifyContent="space-between" sx={{ mt: 2 }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker 
                        label="Date"
                        onChange={handleDateChange}
                        sx={{ width: 150,
                            backgroundColor: '#ececec',
                        }}
                        value={selectedDate}
                        readOnly={isAdded}
                    />
                </LocalizationProvider>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker label="Start time"
                        onChange={handleTimeStartChange}
                        sx={{  width: 150, 
                            backgroundColor: '#ececec',
                        }}
                        value={selectedTimeStart}
                        readOnly={isAdded}
                        />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker label="End time"
                        onChange={handleTimeEndChange}
                        sx={{  width: 150, 
                            backgroundColor: '#ececec',
                        }}
                        value={selectedTimeEnd}
                        readOnly={isAdded}
                        />
                </LocalizationProvider>
                {isAdded ?
                    <DeleteIcon sx={{ cursor: 'pointer', ml: 4,
                        '&:hover': {  color: MINDYOURSELF_MAIN_COLOR }}}
                    onClick={onDelete}
                /> :
                <AddTaskIcon
                    sx={{ cursor: 'pointer', ml: 4,
                        '&:hover': {  color: MINDYOURSELF_MAIN_COLOR } }}
                    onClick={() => { setIsAdded(true); }}
                />
                }
            </Grid>
    </div>
    );
}

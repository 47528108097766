import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import TextField from '@mui/material/TextField';
import { Grid } from '@mui/material';

import {
    PRIMARY_COLOR,
    SECONDARY_COLOR,
    MINDYOURSELF_MAIN_COLOR,
    CANCEL_BUTTON_COLOR
} from '../../constant';
import { queryAPI } from '../../Api';

import {
  Notification
} from '../../Components/Notification';

export default function NotesContainer(row) {
  const [isNoteOpen, setIsNoteOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [maxWidth, setMaxWidth] = React.useState('md');
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [isMobile, setIsMobile] = React.useState(false);
  const [isSessionOpen, setIsSessionOpen] = React.useState(false);
  const [isSessionTerminated, setIsSessionTerminated] = React.useState(false);
  const [current_session_id, setCurrentSessionId] = React.useState(null);
  const [notes, setNotes] = React.useState("");
  const [noteNeedsSaving, setNoteNeedsSaving] = React.useState(false);
  const [isErrorSavingNote, setIsErrorSavingNote] = React.useState(false);

  React.useEffect(() => {
    console.log("isNoteOpen: ", row.row.is_existing_note);
    // Check if user is on a mobile device
    const isMobileDevice = window.matchMedia("screen and (max-width: 768px)").matches;
    setIsMobile(isMobileDevice);
  }, []);

  const handleClickOpen = () => {
    setIsNoteOpen(true);
  };

  const handleClose = () => {
    setIsNoteOpen(false);
    setConfirmOpen(false);
    setIsSessionOpen(false);
    setIsSessionTerminated(true);
  };

  const handleSaveChanges = () => {
    setNoteNeedsSaving(true);
  };

  const handleStay = () => {
    setConfirmOpen(false);
  };

  const handleCloseConfirm = () => {
    setConfirmOpen(true);
  };

  // prepare the data to show in the table
  var name = row.row.username;
  const phone_number = row.row.phone_number;
  const user_id = localStorage.getItem('id');
  var is_existing_note = row.row.is_existing_note;
  var existing_notes = row.row.notes;
  const request_id = row.row.request_id;

  // open a session
  React.useEffect(() => {
    // if session_id exists in local storage, then don't open a new session
    if (!is_existing_note && !isSessionOpen && isNoteOpen) {
      console.log("opening session");
      queryAPI('/session?user_id='+user_id, {
        "psw_id": user_id,
        "appuser_name": name,
        "appuser_phone_number": phone_number,
        "request_id": request_id
      }, 'POST'
      ).then((response) => {
        if (response.status === 200) {
          const session_id = response.data.session_id;
          console.log("session opened with id: " + session_id);
          localStorage.setItem('session_id', session_id);
          setCurrentSessionId(session_id);
          setIsSessionOpen(true);
          setIsSessionTerminated(false);
        } else {
          console.log("error opening session");
        }
      }).catch((error) => {
        console.log(error);
      });
    }
  }, [isNoteOpen, isSessionOpen, is_existing_note]);

  React.useEffect(() => {
    if (!is_existing_note && isSessionTerminated) {
      queryAPI('/session', {
        "user_id": localStorage.getItem('id'),
        "session_id": current_session_id,
        "is_ongoing": false
      }, 'PUT').then((response) => {
        if (response.status === 200) {
          console.log("session closed");
          localStorage.removeItem('session_id');
          setCurrentSessionId(null);
        } else {
          console.log("error closing session");
          localStorage.removeItem('session_id');
          setCurrentSessionId(null);
        }
      }).catch((error) => {
        console.log(error);
      });
    }
  }, [isSessionTerminated, is_existing_note]);

  // save the note
  React.useEffect(() => {
    if (noteNeedsSaving) {
      queryAPI('/note?user_id='+user_id, {
        "session_id": current_session_id,
        "notes": notes
      }, 'POST'
      ).then((response) => {
        if (response.status === 200) {
          console.log("note saved");
          setNoteNeedsSaving(false);
          setIsNoteOpen(false);
          setConfirmOpen(false);
          setIsSessionOpen(false);
          setIsSessionTerminated(true);
          setIsErrorSavingNote(false);
          setNotes("");
        } else {
          setIsErrorSavingNote(true);
          console.log("error saving note");
          setTimeout(() => {
            setIsErrorSavingNote(false);
          }
          , 3000);
        }
      }).catch((error) => {
        console.log(error);
      });
    }
  }, [noteNeedsSaving]);

  // get the content of notes
  React.useEffect(() => {
    if (is_existing_note && !isSessionOpen && isNoteOpen) {
      console.log("in get notes");
      setNotes(existing_notes)
    }
  }, [isNoteOpen, isSessionOpen, is_existing_note]);

  return (
    <Grid>
      {isErrorSavingNote && 
        <Notification
          severity="error"
          message="Error saving note, please try again"
        />
      }
      {is_existing_note ?
        <AddCircleIcon 
          data-testid="existing-note-icon"
          style={{ color: MINDYOURSELF_MAIN_COLOR }}
          onClick={handleClickOpen}
          onMouseEnter={(event) => event.target.style.cursor = "pointer"}
        /> :
        <Button
          onClick={handleClickOpen}
          variant='contained'
          data-testid='new-note-icon'
          size='small'
          sx={{ backgroundColor: PRIMARY_COLOR,
            color: SECONDARY_COLOR,
            ":hover": { backgroundColor: SECONDARY_COLOR, color: PRIMARY_COLOR },
            textTransform: 'none'
          }}
        >
          Start Session
        </Button>
       }
      
      <Dialog
        fullScreen={fullScreen}
        open={isNoteOpen}
        maxWidth={maxWidth}
        onClose={handleCloseConfirm}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Notes for " + name + ""}
        </DialogTitle>
        <DialogContent>
            <DialogContentText sx={{ minWidth:  isMobile ? '100%' : 700, maxWidth:  isMobile ? '100%' : 700 }}>
              <TextField
                label="notes"
                data-testid="notes-textfield"
                variant="outlined"
                size="small"
                fullWidth
                multiline
                rows={10}
                margin='normal'
                onChange={(event) => {
                  setNotes(event.target.value);
                }}
                defaultValue={notes}
              />
            </DialogContentText>
         
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'space-between', ml:2, mr:3, mb:1 }}>
          <Button onClick={ () => setConfirmOpen(true) } 
            sx={{ backgroundColor: CANCEL_BUTTON_COLOR, 
                    color: SECONDARY_COLOR, 
                    ":hover": { backgroundColor: CANCEL_BUTTON_COLOR, color: SECONDARY_COLOR }
                }}
            >
            Cancel
          </Button>
          <Button 
            data-testid="save-note-button"
            onClick={handleSaveChanges}
            disabled={is_existing_note}
            sx={
              { backgroundColor: PRIMARY_COLOR, 
                  color: SECONDARY_COLOR, 
                  ":hover": { backgroundColor: SECONDARY_COLOR, color: PRIMARY_COLOR }
              }
          }>
            Save Notes
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={confirmOpen}
        //onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={maxWidth}
      >
        <DialogTitle id="alert-dialog-title">{"Discard Changes?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to exit without saving?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleStay}
            sx={{ backgroundColor: CANCEL_BUTTON_COLOR,
                    color: SECONDARY_COLOR }}
            autoFocus>
            Cancel
          </Button>
          <Button onClick={handleClose}
            sx={{ backgroundColor: PRIMARY_COLOR,
                    color: SECONDARY_COLOR,
                    ":hover": { backgroundColor: SECONDARY_COLOR, color: PRIMARY_COLOR } }}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}
